<template>
  <div :id="'product-card-' + product.id" class="product-card">
    <div class="product-teeth-icon" v-bind:class="iconColorClass" v-if="product.category_id !== 6">
      <span v-bind:class="iconClass"></span>
    </div>
    <div class="product-teeth-icon violet" v-if="product.category_id === 6">
      <img src="../assets/images/cat-6.svg">
    </div>
    <p class="product-type" v-html="category">
    </p>
    <h4 class="product-title" v-html="product.name">
    </h4>
    <p class="product-price">
      desde<br>
      <span v-html="(product.min_price / 100) + '€'"></span>
    </p>
    <button class="button is-medium is-primary"  v-if="idProductOpen !== product.id && (isClinicLoggedIn || isLabLoggedIn)" @click="loadLabs(product)" :disabled="disable">VIEW LABORATORIES</button>
    <button class="button is-medium is-fullwidth is-primary is-outline" v-if="idProductOpen === product.id" @click="loadLabs(product)" :disabled="disable">CLOSE</button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProductCard',
  props: {
    product: Object,
    idProductOpen: String,
    openCb: Function,
    category: String,
    disable: Boolean
  },
  computed: {
    ...mapGetters({
      isClinicLoggedIn: 'user/isClinicLoggedIn',
      isLabLoggedIn: 'user/isLabLoggedIn'
    }),
    iconColorClass () {
      const cssClass = {}
      switch (this.product.category_id) {
        case 1:
        case 5:
          cssClass['orange'] = true
          break
        case 18:
        case 2:
        case 13:
          cssClass['blue'] = true
          break
        case 14:
        case 15:
        case 16:
          cssClass['green'] = true
          break
        case 7:
        case 3:
          cssClass['violet'] = true
          break
      }
      return cssClass
    },
    iconClass () {
      const cssClass = {}
      cssClass['icon-cat-' + this.product.category_id] = true
      return cssClass
    }
  },
  data () {
    return {
    }
  },
  methods: {
    loadLabs (product) {
      this.openCb(product)
    }
  },
  created () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.product-card{
  position: relative;
  background-color: #FFF;
  width: calc(33.33333333333% - 15px);
  max-height: 330px;
  margin-left: 15px;
  margin-bottom: 70px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  padding: 10px;
  text-align: center;
  transition: all .4s ease-out;
  padding: 20px;
  &:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  .product-teeth-icon{
    position: absolute;
    height: 76px;
    width: 76px;
    top: -38px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #FFF;
    border-radius: 50%;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
    &.orange{
      background-color: #E3914F;
    }
    &.blue{
      background-color: #7791E1;
    }
    &.green{
      background-color: #8FA974;
    }
    &.violet{
      background-color: #9474BA;
    }
    span{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
      color: #FFF;
    }
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 55%;
    }
  }
  .product-type{
    margin-top: 50px;
    text-transform: uppercase;
    color: #57567C;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
  }
  .product-title{
    color: #57567C;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    padding: 10px 0px;
    height: 83px;
  }
  .product-price{
    text-transform: uppercase;
    color: #57567C;
    font-size: .8rem;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    padding: 20px 0px;
    span{
      font-size: 1.2rem;
    }
  }
  button{
    font-size: 1rem;
    &.is-outline{
      color: #57567C;
      &:hover{
        color: #FFFFFF;
      }
    }
  }
}
@media screen and (max-width: 1130px) {
  .product-card{
    width: calc(50% - 15px);
  }
}
@media screen and (max-width: 530px) {
  .product-card{
    width: 100%;
  }
}
</style>
