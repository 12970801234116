<template>
  <div class="products">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
      <div class="search-wrap">
        <div class="row pt-6 pb-6">
          <div id="search-products" class="column is-6 is-offset-3">
            <h1 class="title is-1 has-text-centered">Buscar por producto</h1>
            <div class="control has-icons-right">
              <input class="input is-rounded" type="text" placeholder="Search a service (e.g. full protesis, corona implant…)" v-model="searchText" @input="searchProduct()">
              <span class="icon is-right has-text-centered">
                <span class="icon-search"></span>
              </span>
              <p class="pt-3 has-text-centered has-text-weight-bold" v-if="searchText !== ''">{{products.length}} resultados encontrados</p>
            </div>
          </div>
        </div>
      </div>
    </HeroImage>
    <section id="product-container" class="container">
      <div class="row pt-6">
        <div class="columns">
          <div class="column is-4 filter-column">
            <div class="category-box">
              <v-collapse-group :onlyOneActive="false" class="filter-wrap">
                <!-- Protesis Removibles filter -->
                <v-collapse-wrapper ref="categoryFilterRemovibles" v-on:onStatusChange="checkCategoryRemovibles">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title is-uppercase">Prótesis Removibles</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isCategoryRemoviblesOpen"></span>
                        <span class="icon-filters-open" v-if="!isCategoryRemoviblesOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                    <template v-for="category in protesisRemovibles">
                      <div class="filter-check" v-bind:key="category.id">
                        <label class="checkbox">
                          <input :id="'category-' + category.id" type="checkbox" class="filter-checkbox" @change="updateCategoryFilter($event, category.id)">
                          {{category.name}}
                        </label>
                      </div>
                    </template>
                  </div>
                </v-collapse-wrapper>
                <!-- Protesis fijas filter -->
                <v-collapse-wrapper ref="categoryFilterFijas" v-on:onStatusChange="checkCategoryFijas">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title is-uppercase">Prótesis Fijas Sobre Dientes</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isCategoryFijasOpen"></span>
                        <span class="icon-filters-open" v-if="!isCategoryFijasOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                    <template v-for="category in protesisFijas">
                      <div class="filter-check" v-bind:key="category.id">
                        <label class="checkbox">
                          <input :id="'category-' + category.id" type="checkbox" class="filter-checkbox" @change="updateCategoryFilter($event, category.id)">
                          {{category.name}}
                        </label>
                      </div>
                    </template>
                  </div>
                </v-collapse-wrapper>
                <!-- Protesis filter -->
                <v-collapse-wrapper ref="categoryFilterSobre" v-on:onStatusChange="checkCategorySobre">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title is-uppercase">Prótesis sobre implantes</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isCategorySobreOpen"></span>
                        <span class="icon-filters-open" v-if="!isCategorySobreOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                    <template v-for="category in protesisSobre">
                      <div class="filter-check" v-bind:key="category.id">
                        <label class="checkbox">
                          <input :id="'category-' + category.id" type="checkbox" class="filter-checkbox" @change="updateCategoryFilter($event, category.id)">
                          {{category.name}}
                        </label>
                      </div>
                    </template>
                  </div>
                </v-collapse-wrapper>
                <!-- Protesis filter -->
                <v-collapse-wrapper ref="categoryFilterOrtodoncia" v-on:onStatusChange="checkCategoryOrtodoncia">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title is-uppercase">Ortodoncia</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isCategoryOrtodonciaOpen"></span>
                        <span class="icon-filters-open" v-if="!isCategoryOrtodonciaOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                    <template v-for="category in ortodoncia">
                      <div class="filter-check" v-bind:key="category.id">
                        <label class="checkbox">
                          <input :id="'category-' + category.id" type="checkbox" class="filter-checkbox" @change="updateCategoryFilter($event, category.id)">
                          {{category.name}}
                        </label>
                      </div>
                    </template>
                  </div>
                </v-collapse-wrapper>
              </v-collapse-group>
            </div>

            <div class="dropdown filter-ddl mobile" v-bind:class="{'is-active': isFilterDdlOpen }">
              <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="toggleFilterDdl()">
                  <span class="ddl-label">FILTRAR POR</span>
                  <span class="icon is-small">
                    <span class="recommended-label is-small">Recommended</span>
                    <span class="icon-chevron-down" aria-hidden="true"></span>
                    <!-- <span class="icon-chevron-up" aria-hidden="false"></span> -->
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <a href="#" class="dropdown-item">
                    Dropdown item
                  </a>
                  <a class="dropdown-item">
                    Other dropdown item
                  </a>
                  <a href="#" class="dropdown-item is-active">
                    Active dropdown item
                  </a>
                  <a href="#" class="dropdown-item">
                    Other dropdown item
                  </a>
                </div>
              </div>
            </div>
            <div class="dropdown sort-ddl" v-bind:class="{'is-active': isSortDdlOpen }">
              <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="toggleSortDdl()">
                  <span class="ddl-label">ORDINAR POR</span>
                  <span class="icon is-small">
                    <span class="recommended-label is-small">Recommended</span>
                    <span class="icon-chevron-down" aria-hidden="true"></span>
                    <!-- <span class="icon-chevron-up" aria-hidden="false"></span> -->
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <a href="#" class="dropdown-item">
                    Dropdown item
                  </a>
                  <a class="dropdown-item">
                    Other dropdown item
                  </a>
                  <a href="#" class="dropdown-item is-active">
                    Active dropdown item
                  </a>
                  <a href="#" class="dropdown-item">
                    Other dropdown item
                  </a>
                </div>
              </div>
            </div>
            <hr>
            <div class="filter-header">
              <p class="filter-title">FILTRAR POR</p>
              <p class="filter-result-recap" v-if="minPriceIn.length + materialIdIn.length + categoryIdIn.length > 0"><span>{{products.length}}</span> resultados encontrados</p>
              <button v-if="minPriceIn.length + materialIdIn.length + categoryIdIn.length > 0" @click="clearFilters()">Restablecer todos los filtros</button>
            </div>
            <v-collapse-group :onlyOneActive="false" class="filter-wrap">
              <v-collapse-wrapper ref="materialFilter" v-on:onStatusChange="checkMateriealFilter">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title">MATERIAL</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isMateriealFilterOpen"></span>
                        <span class="icon-filters-open" v-if="!isMateriealFilterOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                    <template v-for="material in materials">
                      <div class="filter-check" v-bind:key="material.id">
                        <label class="checkbox">
                          <input :id="'material-filter-' + material.id" type="checkbox" class="filter-checkbox" @change="updateMaterialFilter($event, material.id)">
                          {{material.name}}
                        </label>
                      </div>
                    </template>
                  </div>
              </v-collapse-wrapper>
              <!-- <v-collapse-wrapper v-on:onStatusChange="checkLocationFilter">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title">LOCATION</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isLocationFilterOpen"></span>
                        <span class="icon-filters-open" v-if="!isLocationFilterOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Implants
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Removable Prosthesis
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Fixed Prosthesis
                        </label>
                      </div>
                  </div>
              </v-collapse-wrapper> -->
              <!-- <v-collapse-wrapper v-on:onStatusChange="checkDeliveryFilter">
                  <div class="header" v-collapse-toggle>
                      <p class="filter-title">DELIVERY TIME</p>
                      <div class="filter-icon">
                        <span class="icon-filters-close" v-if="isDeliveryFilterOpen"></span>
                        <span class="icon-filters-open" v-if="!isDeliveryFilterOpen"></span>
                      </div>
                  </div>
                  <div class="my-content" v-collapse-content>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Implants
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Removable Prosthesis
                        </label>
                      </div>
                      <div class="filter-check">
                        <label class="checkbox">
                          <input type="checkbox">
                          Fixed Prosthesis
                        </label>
                      </div>
                  </div>
              </v-collapse-wrapper> -->
            </v-collapse-group>
          </div>
          <!-- SERVICES COLUMN -->
          <div class="column is-8 services-column">
            <div id="products-list" class="services-wrap">
              <div class="products-cta" v-if="products.length === 0">
                <img class="arrow-top" src="../assets/arrow-top.svg">
                <img class="arrow-left" src="../assets/arrow-sx.svg">
                <h3>Seleccione una categoría o realice una búsqueda para examinar los productos y servicios</h3>
              </div>
              <template v-for="product in products">
                <ProductCard :key="product.id" :product="product" :id-product-open="idOpenService" :open-cb="loadProductOffersByLab" :category="getCategoryName(product.category_id)" :disable="isLoadingProductOffers"></ProductCard>
              </template>
              <transition name="fade">
                <div class="service-item-details" v-if="showLabsList" v-bind:style="styleServiceDetails">
                  <div class="product-offer-wrap no-labs" v-if="labsList.length === 0">
                    <p class="pt-3">There are no laboratories for this product at the moment.</p>
                  </div>
                  <template v-for="lab in labsList">
                    <div class="product-offer-wrap" :key="lab.id">
                      <div class="columns w-full mb-2">
                        <div class="column is-7">
                          <h4 class="lab-title" @click="goToLab(lab.id)" v-html="lab.name"></h4>
                          <p>Garantía: {{getProductInfo(lab.warranty)}}</p>
                          <p>Material: {{getProductInfo(lab.material)}}</p>
                          <div class="description-product-wrap" v-if="lab.description !== null && lab.description !== ''">
                            <p class="pt-2">
                              <span class="icon-info-1-1"></span> <span class="is-underlined">Ver descripción</span>
                            </p>
                            <div class="description-product left-arrow">
                              <p>{{lab.description}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="column is-4 has-text-right">
                          <p class="lab-product-info"><span>{{lab.price / 100}}€</span><br>
                          ENTREGA ESTIMADA EN {{lab.min_days_to_deliver}}-{{lab.max_days_to_deliver}} DIAS</p>
                          <div class="rating-wrap">
                            <span class="icon-star" v-bind:class="{'active': lab.rating >= 1}"></span>
                            <span class="icon-star" v-bind:class="{'active': lab.rating >= 2}"></span>
                            <span class="icon-star" v-bind:class="{'active': lab.rating >= 3}"></span>
                            <span class="icon-star" v-bind:class="{'active': lab.rating >= 4}"></span>
                            <span class="icon-star" v-bind:class="{'active': lab.rating >= 5}"></span>
                            <p><span>{{lab.reviews}}</span> reviews</p>
                          </div>
                        </div>
                        <div class="column is-1">
                          <button v-if="isClinicLoggedIn" class="button cart-btn" @click="goToLab(lab.id, true)">
                            <span class="icon-cart"></span>
                          </button>
                        </div>
                      </div>
                      <!-- <div class="columns mt-4 mb-2" v-if="lab.has_complementaries">
                        <complementary-list :product-offer-id="lab.product_offer_id" :loaded="complementaryLoaded"></complementary-list>
                      </div> -->
                    </div>
                  </template>
                </div>
              </transition>
            </div>
            <div class="columns" v-if="products.length > 0 && productsPagination !== null">
              <div class="column is-6 is-offset-3 all-btn-wrap">
                <button class="button is-medium is-fullwidth is-primary is-outline" @click="loadNextPage()" v-if="productsPagination.next !== null">Ver más productos</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <PreFooterCtaLab></PreFooterCtaLab>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeroImage from '@/components/HeroImage.vue'
import PreFooterCtaLab from '@/components/PreFooterCtaLab.vue'
import ProductCard from '@/components/ProductCard.vue'
import Footer from '@/components/Footer.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import {
  ElemOffset,
  IsEmptyOrNull
} from '@/utils/utils'

export default {
  name: 'Products',
  components: {
    HeroImage,
    PreFooterCtaLab,
    ProductCard,
    Footer
  },
  computed: {
    ...mapGetters({
      isClinicLoggedIn: 'user/isClinicLoggedIn'
    })
  },
  data () {
    return {
      categoriesCards: [],
      materials: [],
      categories: [],
      // Categories
      protesisRemovibles: [],
      protesisFijas: [],
      protesisSobre: [],
      ortodoncia: [],
      //
      products: [],
      minPriceIn: [],
      materialIdIn: [],
      categoryIdIn: [],
      isFilterDdlOpen: false,
      isSortDdlOpen: false,
      // categories filters
      isCategoryRemoviblesOpen: false,
      isCategoryFijasOpen: false,
      isCategorySobreOpen: false,
      isCategoryOrtodonciaOpen: false,
      //
      isMateriealFilterOpen: false,
      isLocationFilterOpen: false,
      isDeliveryFilterOpen: false,
      numberOfColumn: 3,
      heightLabRow: 160,
      idOpenService: null,
      labsList: [],
      showLabsList: false,
      searchText: '',
      searchTimeout: null,
      searchIsLoading: false,
      styleServiceDetails: {
        top: '0px',
        height: '0px'
      },
      pageProducts: 1,
      pageProductsSize: 15,
      productsPagination: null,
      productCategoriesPagination: null,
      productMaterialsPagination: null,
      laboratoriesByProductPagination: null,
      categoriesSliderSettings: {
        centerMode: true,
        centerPadding: '60px',
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 500,
        arrows: false,
        dots: false,
        draggable: true,
        responsive: [
          {
            breakpoint: 1320,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1110,
            settings: {
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 820,
            settings: {
              centerPadding: '10px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 750,
            settings: {
              centerPadding: '100px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 700,
            settings: {
              centerPadding: '80px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 660,
            settings: {
              centerPadding: '60px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 620,
            settings: {
              centerPadding: '40px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 570,
            settings: {
              centerPadding: '20px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 530,
            settings: {
              centerPadding: '130px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 510,
            settings: {
              centerPadding: '120px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 490,
            settings: {
              centerPadding: '100px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 450,
            settings: {
              centerPadding: '80px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 410,
            settings: {
              centerPadding: '60px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 370,
            settings: {
              centerPadding: '40px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 330,
            settings: {
              centerPadding: '30px',
              slidesToShow: 1
            }
          }
        ]
      },
      productsOffersLoaded: 0,
      isLoadingProductOffers: false,
      complementaryWrapMinHeight: 100,
      complementaryLineHeight: 37
    }
  },
  watch: {
    'productsOffersLoaded' (newVal) {
      if (newVal === this.labsList.length) {
        this.showLabsList = true
        this.isLoadingProductOffers = false
      }
    },
    'materials' (newVal) {
      if (newVal.length > 0 && this.categories.length > 0) {
        // this.getProductsList(false, true)
      }
    },
    'categories' (newVal) {
      if (newVal.length > 0 && this.materials.length > 0) {
        // this.getProductsList(false, true)
      }
    }
  },
  methods: {
    getProductInfo (value) {
      if (IsEmptyOrNull(value)) {
        return 'No disponibile'
      }
      return value
    },
    goToLab (labId, withProduct = false) {
      const labPath = withProduct ? '/laboratories/details/' + labId + '/' + this.idOpenService : '/laboratories/details/' + labId
      this.$router.push({ path: labPath })
    },
    loadNextPage () {
      this.getProductsList(true)
    },
    searchProduct () {
      const self = this
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        self.closeLabList()
        self.getProductsList()
        self.scrollToResults()
      }, 500)
      if (this.searchText.trim().length > 1) {
        this.searchIsLoading = true
      } else {
        this.searchIsLoading = false
      }
    },
    filterByCategory (categoryId) {
      const catId = parseInt(categoryId)
      if (catId === 1 || catId === 5) {
        this.$refs.categoryFilterRemovibles.open()
      }
      if (catId === 2 || catId === 13 || catId === 18) {
        this.$refs.categoryFilterFijas.open()
      }
      if (catId === 14 || catId === 15 || catId === 16) {
        this.$refs.categoryFilterSobre.open()
      }
      if (catId === 3 || catId === 6 || catId === 7) {
        this.$refs.categoryFilterOrtodoncia.open()
      }
      this.$nextTick(function () {
        document.getElementById('category-' + categoryId).checked = true
      })
    },
    updateCategoryFilter (e, categoryId, fromQueryString = false) {
      this.closeLabList()
      if (fromQueryString) {
        this.categoryIdIn.push(categoryId)
      } else {
        if (e.target.checked) {
          // add to category filters
          this.categoryIdIn.push(categoryId)
        } else {
          // remove from category filters
          const index = this.categoryIdIn.indexOf(categoryId)
          if (index > -1) {
            this.categoryIdIn.splice(index, 1)
          }
        }
      }
      this.getProductsList(false, true)
    },
    updateMaterialFilter  (e, materialId) {
      this.closeLabList()
      if (e.target.checked) {
        // add to category filters
        this.materialIdIn.push(materialId)
      } else {
        // remove from category filters
        const index = this.materialIdIn.indexOf(materialId)
        if (index > -1) {
          this.materialIdIn.splice(index, 1)
        }
      }
      this.getProductsList(false, true)
    },
    checkMateriealFilter (vm) {
      this.isMateriealFilterOpen = vm.status
    },
    checkLocationFilter (vm) {
      this.isLocationFilterOpen = vm.status
    },
    checkDeliveryFilter (vm) {
      this.isDeliveryFilterOpen = vm.status
    },
    checkCategoryRemovibles (vm) {
      this.isCategoryRemoviblesOpen = vm.status
    },
    checkCategoryFijas (vm) {
      this.isCategoryFijasOpen = vm.status
    },
    checkCategoryOrtodoncia (vm) {
      this.isCategoryOrtodonciaOpen = vm.status
    },
    checkCategorySobre (vm) {
      this.isCategorySobreOpen = vm.status
    },
    toggleSortDdl () {
      this.isSortDdlOpen = !this.isSortDdlOpen
    },
    toggleFilterDdl () {
      this.isFilterDdlOpen = !this.isFilterDdlOpen
    },
    closeLabList () {
      if (this.idOpenService !== null) {
        const clickedServicePrev = document.getElementById('product-card-' + this.idOpenService)
        clickedServicePrev.style.marginBottom = 70 + 'px'
        this.idOpenService = null
        this.showLabsList = false
      }
    },
    complementaryLoaded (complementaryList, extrasList) {
      // Add the height of the complementary to the #service-item-details wrapper
      const clickedProduct = document.getElementById('product-card-' + this.idOpenService)
      const currentHeight = parseInt(this.styleServiceDetails.height.split('p')[0])
      const newHeight = currentHeight + (this.complementaryWrapMinHeight + (complementaryList.length * this.complementaryLineHeight) + (extrasList.length * this.complementaryLineHeight))

      clickedProduct.style.marginBottom = newHeight + 'px'
      this.styleServiceDetails.height = newHeight + 'px'
    },
    getProductOffersByLab (index, productId) {
      const self = this
      this.$store.dispatch('lab/getProductOfferByLab', {
        labId: this.labsList[index].id,
        priceIn: '',
        minDaysToDeliverIn: '',
        maxDaysToDeliverIn: '',
        productIdIn: productId,
        cb: (response) => {
          self.labsList[index].price = response[0].price
          self.labsList[index].min_days_to_deliver = response[0].min_days_to_deliver
          self.labsList[index].max_days_to_deliver = response[0].max_days_to_deliver

          self.labsList[index].description = response[0].description
          self.labsList[index].warranty = response[0].warranty
          self.labsList[index].material = response[0].material

          self.labsList[index].product_offer_id = response[0].id
          self.labsList[index].has_complementaries = response[0].has_complementaries
          self.productsOffersLoaded++
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    loadProductOffersByLab (product) {
      // Get labs with service id
      // Once I have the number of labs, calculate the height of the list
      // #service-item-details.height = (labs.length * heightLabRow) + (padding-top + padding-bottom)
      // #service-item-details.height = (labs.length * 100) + (24 + 36)
      // NOW, take the bottom coordinates of the service, add a margin (24?). set this value to the top value of the #service-item-details
      // then add this value as a margin-bottom of the selected service
      // show #service-item-details
      const clickedService = document.getElementById('product-card-' + product.id)
      this.productsOffersLoaded = 0
      if (this.showLabsList) {
        // CLOSE
        if (this.idOpenService !== product.id) {
          const clickedServicePrev = document.getElementById('product-card-' + this.idOpenService)
          clickedServicePrev.style.marginBottom = 70 + 'px'
        } else {
          clickedService.style.marginBottom = 70 + 'px'
        }
        this.idOpenService = null
        this.showLabsList = false
      } else {
        // OPEN
        const self = this
        this.idOpenService = product.id
        this.isLoadingProductOffers = true
        this.$store.dispatch('products/getLaboratoriesByProduct', {
          productId: product.id,
          page: 1,
          pageSize: 50,
          cb: (response) => {
            self.laboratoriesByProductPagination = response.pagination
            for (let y = 0; y < response.length; y++) {
              response[y].price = null
              response[y].min_days_to_deliver = null
              response[y].max_days_to_deliver = null
            }
            self.labsList = response
            // Calc height accordion
            const labsListLength = (self.labsList.length === 0) ? 1 : self.labsList.length
            const serviceDetailHight = (labsListLength * self.heightLabRow) + (24 + 36)
            clickedService.style.marginBottom = serviceDetailHight + 'px'
            self.styleServiceDetails.top = clickedService.offsetTop + clickedService.offsetHeight + 'px'
            self.styleServiceDetails.height = serviceDetailHight + 'px'

            if (self.labsList.length > 0) {
              // Get product offers
              for (let i = 0; i < self.labsList.length; i++) {
                self.labsList[i].product_offer_id = null
                self.getProductOffersByLab(i, product.id)
              }
            } else {
              self.showLabsList = true
              self.isLoadingProductOffers = false
            }
          },
          cbError: (error) => {
            self.isLoadingProductOffers = false
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: error.response.data.error
              }
            })
          }
        })
      }
    },
    clearFilters () {
      this.minPriceIn = []
      this.materialIdIn = []
      this.categoryIdIn = []
      const filtersCheck = document.getElementsByClassName('filter-checkbox')
      filtersCheck.forEach((checkbox) => {
        checkbox.checked = false
      })
      this.getProductsList()
    },
    getCategories (isFromQueryString = false) {
      const self = this
      this.$store.dispatch('products/getProductCategories', {
        cb: (response) => {
          for (let i = 0; i < response.length; i++) {
            response[i].products = []
          }
          self.productCategoriesPagination = response.pagination
          self.categories = response
          self.setMacroCategories(response)
          if (isFromQueryString) {
            self.filterByCategory(self.$route.query.category)
          }
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    getMaterials () {
      const self = this
      this.$store.dispatch('products/getProductMaterials', {
        cb: (response) => {
          self.productMaterialsPagination = response.pagination
          self.materials = response
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    getCategoryName (categoryId) {
      const result = this.categories.find(({ id }) => id === categoryId)
      return IsEmptyOrNull(result) ? '-' : result.name
    },
    getMaterialName (materialId) {
      const result = this.materials.find(({ id }) => id === materialId)
      if (!IsEmptyOrNull(result)) {
        return 'Material: ' + result.name
      } else {
        return 'No material'
      }
    },
    getProductsList (nextPage = false, fullSet = false) {
      const self = this
      if (nextPage) {
        this.pageProducts = this.productsPagination.next.page
      } else {
        this.pageProducts = 1
        this.products = []
      }

      if (this.materialIdIn.length > 0 || this.categoryIdIn.length > 0 || !IsEmptyOrNull(this.searchText)) {
        const payload = {
          page: this.pageProducts,
          pageSize: (fullSet) ? 10000 : this.pageProductsSize,
          minPriceIn: this.minPriceIn.toString(),
          materialIdIn: this.materialIdIn.toString(),
          categoryIdIn: this.categoryIdIn.toString(),
          searchString: this.searchText,
          productOffersPresent: true,
          cb: (response) => {
            // console.log(response.pagination)
            if (IsEmptyOrNull(response.pagination)) {
              self.productsPagination = response.pagination
            } else {
              if (IsEmptyOrNull(response.pagination.next)) {
                // not anymore products
                self.productsPagination.next = null
              }
            }
            self.searchIsLoading = false
            for (let i = 0; i < response.length; i++) {
              if (!response[i].extra) {
                self.products.push(response[i])
              }
            }
          },
          cbError: (error) => {
            console.log(error)
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: error.response.data.error
              }
            })
          }
        }
        this.$store.dispatch('products/getProducts', payload)
      }
    },
    setMacroCategories (categories) {
      this.protesisRemovibles = []
      this.protesisFijas = []
      this.protesisSobre = []
      this.ortodoncia = []
      for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === 1 || categories[i].id === 5) {
          this.protesisRemovibles.push(categories[i])
        }

        if (categories[i].id === 2 || categories[i].id === 13 || categories[i].id === 18) {
          this.protesisFijas.push(categories[i])
        }

        if (categories[i].id === 14 || categories[i].id === 15 || categories[i].id === 16) {
          this.protesisSobre.push(categories[i])
        }

        if (categories[i].id === 3 || categories[i].id === 6 || categories[i].id === 7) {
          this.ortodoncia.push(categories[i])
        }
      }
    },
    /* getCategoriesCards (categories) {
      // Get sub-array of first n elements after shuffled
      this.categoriesCards = GetArrayRandomElements(categories, 3)
    }, */
    scrollToResults () {
      const destination = document.getElementById('product-container')
      const destinationOffset = ElemOffset(destination)
      window.scroll({
        top: destinationOffset.top - 130,
        behavior: 'smooth'
      })
    }
  },
  created () {
    if (!IsEmptyOrNull(this.$route.query.category)) {
      this.updateCategoryFilter(null, parseInt(this.$route.query.category), true)
      this.getCategories(true)
    } else {
      this.getCategories()
    }

    this.getMaterials()
    if (!IsEmptyOrNull(this.$route.query.search)) {
      this.searchText = this.$route.query.search
      this.searchProduct()
    }
  }
}
</script>

<style scoped lang="scss">
.products{
  width: 100%;
  .search-wrap{
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    max-width: 1280px;
    height: 180px;
    // max-width: 1000px;
    h1{
      text-align: center;
      color: #FFFFFF;
    }
    input{
      height: 50px;
      &::placeholder {
        color: #57567C;
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder { /* Edge 12 -18 */
        color: #57567C;
      }
    }
    .icon{
      .icon-search{
        position: relative;
        display: block;
        height: 39px;
        width: 39px;
        right: 8px;
        margin-top: 5px;
        background-color: #E3914F;
        border-radius: 50%;
        line-height: 39px;
        color: #FFFFFF;
        font-size: 1.4rem;
      }
    }
    p{
      font-size: 1.3rem;
    }
  }
  .filter-column{
    .dropdown{
      width: 100%;
      max-width: 329px;
      &.mobile{
        display: none;
      }
      .dropdown-trigger{
        width: 100%;
        button{
          position: relative;
          justify-content: space-between;
          width: 100%;
          border: 1px solid #57567C;
          background-color: transparent;
          .icon{
            position: relative;
            top: -5px;
            width: auto;
            margin-right: 0px;
            font-size: .8rem;
            .ddl-label{
              font-size: 1.2rem;
              position: relative;
              top: 4px;
            }
            .recommended-label{
              position: relative;
              top: 6px;
            }
            .icon-chevron-down, .icon-chevron-up{
              position: relative;
              top: 6px;
              right: -5px;
            }
          }
        }
      }
    }
    .dropdown-menu{
      width: 100%;
      max-width: 329px;
    }
    hr{
      width: 100%;
      max-width: 329px;
      background-color: #57567C;
    }
    .filter-header{
      position: relative;
      width: 100%;
      max-width: 329px;
      margin-bottom: 17px;
      padding-bottom: 17px;
      border-bottom: 1px solid rgba(87, 86, 124, .2);
      .filter-title{
        color: #57567C;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 23px;
      }
      .filter-result-recap{
        position: absolute;
        top: 0px;
        right: 0px;
        color: #57567C;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 23px;
        span{
          font-weight: 600;
        }
      }
      button{
        border: 0px;
        padding: 0px;
        background-color: transparent;
        text-decoration: underline;
      }
    }
    .category-box{
      width: 100%;
      max-width: 329px;
      border-radius: 8px;
      background-color: #FFFFFF;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      padding: 21px;
      padding-top: 31px;
      padding-bottom: 7px;
      margin-bottom: 21px;
      .filter-wrap{
        .vc-collapse{
          border-bottom: 0px;
        }
      }
    }
    .filter-wrap{
      width: 100%;
      max-width: 329px;
      .vc-collapse{
        margin-bottom: 17px;
        padding-bottom: 17px;
        border-bottom: 1px solid rgba(87, 86, 124, .2);
        .header{
          width: 100%;
          position: relative;
          .filter-title{
            color: #57567C;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 23px;
          }
          .filter-icon{
            position: absolute;
            top: 0px;
            right: 0px;
            color: #57567C;
            letter-spacing: 0;
            line-height: 23px;
            font-size: 1.3rem;
          }
        }
        .v-collapse-content{
          .filter-check{
            margin-top: 10px;
            label{
              color: #57567C;
              font-size: 14px;
              letter-spacing: 0;
            }
            input{
              position: relative;
              top: 5px;
              margin-right: 10px;
              height: 21px;
              width: 21px;
              background-color: transparent;
              color: #57567C;
              border: 1px solid #57567C;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
  .services-column {
    .services-wrap{
      position: relative;
      display: flex;
      flex-wrap: wrap;
      .products-cta{
        position: relative;
        width: 100%;
        height: 300px;
        border-radius: 8px;
        background-color: #F6F4EF;
        box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
        display: flex;
        justify-content: center;
        align-content: center;
        h3{
          font-size: 1.5rem;
          align-self: center;
          text-align: center;
          padding: 0 80px;
        }
        .arrow-top{
          position: absolute;
          top: -80px;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 999999999999;
        }
        .arrow-left{
          position: absolute;
          top: 50%;
          left: -60px;
          transform: translate(0, -50%);
        }
      }
      .service-item-details{
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 10;
        width: 100%;
        padding-top: 24px;
        padding-bottom: 36px;
        // max-height: 500px;
        overflow: hidden;
        overflow-y: auto;
        .product-offer-wrap{
          width: 100%;
          // height: 100px;
          margin-bottom: 20px;
          // display: flex;
          // justify-content: space-between;
          padding-left: 20px;
          padding-bottom: 14px;
          border-bottom: 1px solid rgba(87, 86, 124, .2);
          .w-full{
            width: 100%;
          }
          .cart-btn{
            border: 0px;
            background-color: #E3914F;
            color: #FFF;
            border-radius: 50%;
            height: 52px;
            width: 52px;
          }
          .lab-title{
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
          .description-product-wrap{
            position: relative;
            &:hover{
              .description-product {
                opacity: 1;
              }
            }
            .description-product {
              position: absolute;
              left: 150px;
              top: 50%;
              padding: 10px;
              max-width: 300px;
              max-height: 500px;
              transform: translate(0, -50%);
              background-color: #FFF;
              border: 1px solid rgb(87, 86, 124);
              border-radius: 5px;
              box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
              cursor: pointer;
              opacity: 0;
              transition: opacity .4s ease-out;
              &.left-arrow{
                &:before{
                  content:'';
                  display: block;
                  position: absolute;
                  right: calc(100% - 1px);
                  top: 50%;
                  z-index: 2;
                  transform: translate(0, -50%);
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 12.5px 21.7px 12.5px 0;
                  border-color: transparent #FFF transparent transparent;
                }
                &:after{
                  content:'';
                  display: block;
                  position: absolute;
                  right: calc(100% + 1px);
                  top: 50%;
                  z-index: 1;
                  transform: translate(0, -50%);
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 12.5px 21.7px 12.5px 0;
                  border-color: transparent rgb(87, 86, 124) transparent transparent;
                }
              }
              &.right-arrow{
                &:before{
                  content:'';
                  display: block;
                  position: absolute;
                  left: calc(100% - 1px);
                  top: 50%;
                  z-index: 2;
                  transform: translate(0, -50%);
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 12.5px 0 12.5px 21.7px;
                  border-color: transparent transparent transparent #FFF;
                }
                &:after{
                  content:'';
                  display: block;
                  position: absolute;
                  left: calc(100% + 1px);
                  top: 50%;
                  z-index: 1;
                  transform: translate(0, -50%);
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 12.5px 0 12.5px 21.7px;
                  border-color: transparent transparent transparent rgb(87, 86, 124);
                }
              }
            }
          }
          .icon-info-1-1{
            display: inline-block;
            background-color: rgb(87, 86, 124);
            color: #FFF!important;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            &:before{
              color: #FFF!important;
            }
          }
          .lab-product-info{
            font-size: .8rem;
            span{
              font-weight: 600;
              font-size: 1.2rem;
            }
          }
          .rating-wrap{
            float: right;
            margin-top: 8px;
            display: flex;
            .icon-star{
              opacity: .1;
              &.active{
                opacity: 1;
              }
            }
            p{
              margin-top: -2px;
              padding-left: 16px;
              font-size: .8rem;
              span{
                font-weight: 600;
                font-size: .9rem;
              }
            }
          }
          .lab-cta{
            padding-top: 8px;
            button{
              font-size: .9rem;
              color: #E3914F;
              border-color: #E3914F;
              &:focus:hover{
                background-color: #57567C;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .products{
    .filter-column{
      padding-left: 28px;
    }
    .services-column{
      padding-right: 28px;
    }
  }
}

@media screen and (max-width: 768px) {
  .products{
    .container{
      .row{
        &.pb-6{
          padding-bottom: 0px!important;
        }
      }
    }
    .filter-column{
      padding-right: 28px;
      margin-bottom: 42px;
      .dropdown{
        width: 48%;
        .recommended-label{
          display: none;
        }
        &.filter-ddl{}
        &.sort-ddl{
          float: right;
        }
        &.mobile{
          display: inline-block;
        }
      }
      hr{
        display: none;
      }
      .filter-header {
        display: none;
      }
      .filter-wrap {
        display: none;
      }
    }
    .services-column{
      .all-btn-wrap{
        padding-left: 25px;
      }
    }
  }
}

</style>
